const STATS_ENDPOINT = `${process.env.STRAPI_API_URL}/api/scheduler/statistics`

const request = async (url: string, token: string) => {
  const response = await fetch(url, {
    headers: new Headers({
      Authorization: 'Bearer ' + token,
    }),
  })
  if (response.status == 401) {
    throw new Error('Unauthorized')
  }
  return await response.json()
}

export const getUserStats = async (token: string) =>
  request(`${STATS_ENDPOINT}/user`, token)

export const getCourseStats = async (token: string, courseId: Number) =>
  request(`${STATS_ENDPOINT}/course/${courseId}`, token)
